import React, { useRef } from 'react';
import { RicosNodeContextProvider } from 'ricos-context';
import { Node_Type } from 'wix-rich-content-common';

var styles = {"viewMode":"Xarv1","editMode":"JQ1Tq","container":"G7VOo","cell":"HmWJC"};

var CELL_AUTO_MIN_WIDTH = 120;
({
  FORMATTING: Node_Type.TABLE + ".formatting",
  VERTICAL_ALIGNMENT: Node_Type.TABLE + ".verticalAlignment",
  BACKGROUND_COLOR: Node_Type.TABLE + ".backgroundColor",
  ROW_HEADER: Node_Type.TABLE + ".rowHeader",
  COLUMN_HEADER: Node_Type.TABLE + ".columnHeader",
  BORDER: Node_Type.TABLE + ".border",
  CONTEXT: Node_Type.TABLE + ".contextMenu"
});
({
  BORDER: Node_Type.TABLE + ".border",
  VERTICAL_ALIGNMENT: Node_Type.TABLE + ".verticalAlignment",
  CONTEXT: Node_Type.TABLE + ".contextMenu"
});

var ROOT = "TableComponent";

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) {
    for (var _iterator2 = _createForOfIteratorHelperLoose(__getOwnPropSymbols(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
function normalizeColumnWidths(colsMinWidths, colsRelativeWidths) {
  var colsWithDefaults = colsRelativeWidths.map(function (originalWidth, i) {
    var minWidth = colsMinWidths[i] || CELL_AUTO_MIN_WIDTH;
    var width = originalWidth < minWidth ? minWidth : originalWidth;
    return {
      width: width,
      minWidth: minWidth
    };
  });
  var ratios = colsWithDefaults.map(function (c) {
    return c.width / c.minWidth;
  });
  var smallestRatio = Math.min.apply(Math, ratios);
  return colsWithDefaults.map(function (_ref) {
    var minWidth = _ref.minWidth,
      width = _ref.width;
    return {
      minWidth: minWidth,
      width: width / smallestRatio
    };
  });
}
var Table = function Table(_ref2) {
  var node = _ref2.node,
    nodeIndex = _ref2.nodeIndex,
    RicosNodesRenderer = _ref2.RicosNodesRenderer,
    NodeViewContainer = _ref2.NodeViewContainer;
  var _a;
  var tableRef = useRef(null);
  var _ref3 = node.tableData || {},
    _ref3$dimensions = _ref3.dimensions,
    _ref3$dimensions2 = _ref3$dimensions === void 0 ? {} : _ref3$dimensions,
    _ref3$dimensions2$col = _ref3$dimensions2.colsMinWidth,
    colsMinWidth = _ref3$dimensions2$col === void 0 ? [] : _ref3$dimensions2$col,
    _ref3$dimensions2$col2 = _ref3$dimensions2.colsWidthRatio,
    colsWidthRatio = _ref3$dimensions2$col2 === void 0 ? [] : _ref3$dimensions2$col2;
  var colStyles = normalizeColumnWidths(colsMinWidth, colsWidthRatio);
  return /* @__PURE__ */React.createElement(NodeViewContainer, {
    node: node,
    nodeIndex: nodeIndex,
    withHorizontalScroll: true
  }, /* @__PURE__ */React.createElement("div", {
    ref: tableRef,
    className: styles.viewMode
  }, /* @__PURE__ */React.createElement("table", {
    className: styles.container,
    "data-hook": ROOT
  }, /* @__PURE__ */React.createElement("colgroup", null, colStyles.map(function (colStyle, index) {
    return /* @__PURE__ */React.createElement("col", {
      key: index,
      style: colStyle
    });
  })), /* @__PURE__ */React.createElement("tbody", null, /* @__PURE__ */React.createElement(RicosNodeContextProvider, {
    parentNode: node,
    parentIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(RicosNodesRenderer, {
    nodes: (_a = node.nodes) != null ? _a : []
  }))))));
};
var TableRenderer = function TableRenderer(_a) {
  var _b = _a,
    node = _b.node,
    props = __objRest(_b, ["node"]);
  return /* @__PURE__ */React.createElement(Table, __spreadValues({
    key: node.id,
    node: node
  }, props));
};

export { TableRenderer as default };

import 'wix-rich-content-plugin-table/dist/loadable/viewer/es/styles.viewer.min.global.css'